import 'react-phone-input-2/lib/style.css'
import classNames from 'classnames'
import { format } from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, FieldErrors, useFieldArray, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { CountryData } from 'react-phone-input-2'
import generateRandomId from 'src/helpers/generate-random-id'
import { confirmationVariants } from 'src/modules/confirmation/constants/confirmation-variants'
import {
  Accordion,
  Button,
  FormattedText,
  FormControl,
  Icon,
  Loader,
  Modal,
  PhoneNumberField,
  SelectField,
  TextField,
  Typography,
} from 'src/modules/core/components'
import { MessageInput } from 'src/modules/core/components/message-input/message-input'
import { getDefaultCountryCode, getPhoneAndCountryCode } from 'src/modules/core/utils/phone-input.utils'
import { maxMessageLength } from 'src/modules/event/constants/event.constants'
import { eventEditSchema } from 'src/modules/event/schemas/event-edit.schema'
import { formatEventDate, generateEventMessage, getExpiredReminderOffsets } from 'src/modules/event/utils/helpers'
import { PreviewReminderModal } from 'src/modules/reminder/components'
import { SendReminderImmediatelyModal } from 'src/modules/reminder/components/send-reminder-immeditely-modal'
import { timingOptions } from 'src/modules/user/constants/profile.constants'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@material-ui/core'
import { EventSuggestedNumbersHelper } from '../../utils/EventSuggestedNumbersHelper'
import classes from './edit-event-modal.module.scss'

import type { UpdateExtendEventBodyDto, EventData } from 'src/modules/event/types/event.types'
import type {
  EditEventForm,
  EditEventFormData,
  EditEventModalProps,
  EditEventModalContentProps,
} from './edit-event-modal.interface'
import { getEventDate } from 'src/helpers/get-event-date'

const MAX_RECEIVER_NUMBERS = 10

export function EditEventModal({
  onClose,
  modalTitle = <FormattedText id="configuration.editReminderModal.title" />,
  isLoading,
  ...props
}: EditEventModalProps) {
  if (isLoading) {
    return (
      <Modal
        onClose={onClose}
        title={
          <Typography variant="h3" className={classes.modalTitle}>
            {modalTitle}
          </Typography>
        }
      >
        <Loader isLoading={isLoading} />
      </Modal>
    )
  }

  return <EditEventModalContent onClose={onClose} modalTitle={modalTitle} {...props} />
}

export function EditEventModalContent({
  profileData,
  event,
  initialPhoneNumbers,
  onClose,
  handleSave,
  modalTitle,
}: EditEventModalContentProps) {
  let defaultCountryCode = getDefaultCountryCode(profileData)

  // Use initialPhoneNumbers if provided, otherwise use default logic
  const defaultReceiverNumbers = useMemo(() => {
    if (initialPhoneNumbers?.length) {
      return initialPhoneNumbers.map((n) => n.phone)
    }

    const eventPhoneNumbers = Array.from(
      new Set([...(event?.receiver_numbers ?? []), ...EventSuggestedNumbersHelper.getPendingSuggestions(event)]),
    ).filter(Boolean)

    if (eventPhoneNumbers.length > 0) {
      return eventPhoneNumbers
    }
    return profileData.usage_mode === 'personal' ? profileData.phone_numbers : []
  }, [initialPhoneNumbers, profileData.usage_mode, profileData.phone_numbers, event])

  if (defaultReceiverNumbers.length === 0) {
    defaultReceiverNumbers.push('')
  }

  // hooks
  const {
    register,
    handleSubmit,
    control,
    watch,
    setError,
    formState: { isDirty, errors, dirtyFields, isSubmitting },
  } = useForm<EditEventForm & { formError?: string }>({
    defaultValues: {
      sender_name: event?.default_sender_name ? profileData?.sender_name : event?.sender_name || '',
      text: event?.default_message_text ? profileData?.default_message_text : event?.text || '',
      location: event?.default_location ? profileData?.default_location : event?.location || '',
      receiver_numbers: defaultReceiverNumbers.map((n) => ({
        id: generateRandomId(),
        ...getPhoneAndCountryCode(n, defaultCountryCode),
      })),
      time_before: event.reminders?.data?.length
        ? event.reminders?.data?.map((t) => t.time_before.toString())
        : profileData?.default_time_before?.map((t) => t.toString()),
    },
    // @ts-ignore
    resolver: yupResolver(eventEditSchema),
  })
  const [isPreview, setIsPreview] = useState<boolean>(false)
  const [previewReminder, setPreviewReminder] = useState<EventData>(event)

  const [sendImmediatelyState, setSendImmediatelyState] = useState<'closed' | 'open' | 'send'>('closed')

  const { formatMessage } = useIntl()
  const { text } = watch()

  const eventDate = useMemo(() => getEventDate(event), [event])
  const originalEventDate = useMemo(
    () => new Date(Number(event.original_event_date ?? event.event_date)),
    [event.original_event_date, event.event_date],
  )

  const isPastEvent = useMemo(() => new Date() > eventDate, [eventDate])

  const amountOfReceiverNumbers = defaultReceiverNumbers.length || 1

  const eventMessage = useMemo(
    () => generateEventMessage({ ...profileData }, { ...previewReminder, text }),
    [previewReminder, profileData, text],
  )

  const handleClosePreview = useCallback(() => {
    setIsPreview(false)
  }, [])

  const {
    fields: phoneNumbers,
    append: addPhoneNumber,
    remove: removePhoneNumber,
  } = useFieldArray({
    control,
    name: 'receiver_numbers',
  })

  function handleAddPhoneNumber() {
    if (phoneNumbers.length < MAX_RECEIVER_NUMBERS) {
      addPhoneNumber({
        id: generateRandomId(),
        country: defaultCountryCode,
        phone: '',
      })
    }
  }

  useEffect(() => {
    const subscription = watch((value) =>
      setPreviewReminder((prevState) => ({
        ...prevState,
        ...value,
        receiver_numbers: value.receiver_numbers.map((pn) => pn.phone),
      })),
    )
    return () => subscription.unsubscribe()
  }, [watch])

  const onSubmit = useCallback(
    async (data: EditEventFormData) => {
      if (isDirty || !data.active) {
        if (eventMessage.length > maxMessageLength) {
          return setError('text', {
            type: 'custom',
            message: `Default SMS Reminder must be shorter than ${maxMessageLength} characters`,
          })
        }

        const newData: EditEventFormData = {
          id: event.id, // Will either refer to individual event or parent recurring event
        }

        const dirtyKeys = Object.keys(dirtyFields).filter((key) => dirtyFields[key])
        for (const [key, value] of Object.entries(data)) {
          if (dirtyKeys.includes(key)) {
            newData[key] = value
          }
        }

        newData.receiver_numbers = data.receiver_numbers
        newData.time_before = data.time_before.map(Number)
        if (!event.active) newData.active = true

        const { receiver_numbers, ...rest } = newData
        const updateData: UpdateExtendEventBodyDto = rest

        if (newData.receiver_numbers) {
          const receiverNumbers = newData.receiver_numbers.filter((n) => n.phone && n.phone !== '+')
          if (receiverNumbers.length) {
            const countryCode = receiverNumbers[receiverNumbers.length - 1].country
            if (countryCode) {
              localStorage.setItem('defaultCountryCode', countryCode)
            }
          }
          updateData.receiver_numbers = receiverNumbers.length ? receiverNumbers.map((rn) => rn.phone) : null
          updateData.suggestions_reviewed = true
        }

        if (isPastEvent) {
          return setError('time_before', {
            type: 'custom',
            message: 'Event is in the past, cannot set reminders',
          })
        }

        if (sendImmediatelyState !== 'send') {
          const timingValues = data.time_before?.length
            ? data.time_before.map((time) => +time)
            : profileData?.default_time_before ?? []

          const expiredOffsets = getExpiredReminderOffsets(eventDate, timingValues)
          if (expiredOffsets.length > 0) {
            setSendImmediatelyState('open')
            return
          }
        }

        if (sendImmediatelyState === 'send') {
          updateData.send_reminder_immediately = true
          setSendImmediatelyState('closed')
        }

        try {
          await handleSave(updateData.id, updateData)
        } catch (error) {
          return setError('formError', {
            type: 'custom',
            message: error.message,
          })
        }

        return
      }

      onClose()
    },
    [
      isDirty,
      eventMessage.length,
      dirtyFields,
      eventDate,
      setError,
      handleSave,
      onClose,
      event,
      profileData,
      isPastEvent,
      sendImmediatelyState,
    ],
  )

  const handleCloseReminderImmediatelyModal = useCallback(() => {
    setSendImmediatelyState('closed')
  }, [])

  const handleSendReminderImmediately = useCallback(() => {
    setSendImmediatelyState('send')
  }, [])

  useEffect(() => {
    if (sendImmediatelyState === 'send') {
      handleSubmit(onSubmit)()
    }
  }, [sendImmediatelyState, handleSubmit, onSubmit])

  const acceptanceVariant = useMemo(() => {
    if (
      !profileData?.appointment_confirmation ||
      !event.active ||
      event.confirmed === null ||
      (event.confirmed === 'pending' && isPastEvent)
    ) {
      return null
    }

    if (event.confirmed === 'pending') {
      return confirmationVariants.waiting
    }

    if (event.confirmed === 'confirmed') {
      return confirmationVariants.accept
    }

    if (event.confirmed === 'cancelled') {
      return confirmationVariants.reject
    }

    return null
  }, [profileData?.appointment_confirmation, event.active, event.confirmed, isPastEvent])

  return (
    <>
      <Modal
        style={{ maxWidth: '720px' }}
        onClose={onClose}
        title={
          <Typography variant="h3" className={classes.modalTitle}>
            {modalTitle}
          </Typography>
        }
      >
        <form
          className={classNames(classes.root, { [classes.hidden]: isPreview })}
          // @ts-ignore
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={classes.header}>
            <div>
              <Typography variant="h2" className={classes.title}>
                {event.title || <FormattedText id="reminder.noTitle" />}
              </Typography>
              <Typography variant="body" className={classes.regular}>
                {formatEventDate(eventDate, profileData.google_calendar_date_order)}
                {', '}
                {event.all_day ? <FormattedText id="reminder.allDay" /> : format(originalEventDate, 'h:mma')}
              </Typography>
              {event.event_location ? (
                <Typography variant="body" className={classes.eventLocation}>
                  {event.event_location}
                </Typography>
              ) : null}
            </div>
            <div className={classes.statusWrapper}>
              {acceptanceVariant && (
                <div
                  className={classNames(classes.statusBadge, {
                    [classes.statusBadgeAccept]: acceptanceVariant === confirmationVariants.accept,
                    [classes.statusBadgeWaiting]: acceptanceVariant === confirmationVariants.waiting,
                    [classes.statusBadgeRejected]: acceptanceVariant === confirmationVariants.reject,
                  })}
                >
                  {acceptanceVariant === 'accept' && (
                    <Typography variant="body" className={classes.statusText}>
                      <FormattedText id="configuration.editReminder.status.accepted" />
                    </Typography>
                  )}
                  {acceptanceVariant === 'waiting' && (
                    <Typography variant="body" className={classes.statusText}>
                      <FormattedText id="configuration.editReminder.status.waiting" />
                    </Typography>
                  )}
                  {acceptanceVariant === 'reject' && (
                    <Typography variant="body" className={classes.statusText}>
                      <FormattedText id="configuration.editReminder.status.rejected" />
                    </Typography>
                  )}
                </div>
              )}
            </div>
          </div>
          {(errors.formError || event.errors.length > 0) && (
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gridGap={6} marginBottom={2}>
              <Icon name="eventCardError" />
              <span className={classes.error}>{errors.formError?.message || event.errors.join(', ')}</span>
            </Box>
          )}
          {phoneNumbers.map((phone, index) => (
            <FormControl
              label={`${formatMessage({
                id: 'configuration.editReminderModal.label.phoneNumber',
              })} ${index + 1}`}
              error={errors.receiver_numbers && errors.receiver_numbers[index]?.phone?.message}
              className={classes.textFieldWrapper}
              key={phone.id}
            >
              <Controller
                control={control}
                name={`receiver_numbers.${index}`}
                defaultValue={phone}
                render={(props) => (
                  <Box display="flex" alignItems="center" gridColumnGap={10}>
                    <PhoneNumberField
                      disableDropdown={isPastEvent}
                      disabled={isPastEvent}
                      value={phone.phone}
                      country={phone.country}
                      error={errors.receiver_numbers && errors.receiver_numbers[index]?.phone.message}
                      onChange={(value, country: CountryData) => {
                        props.field.onChange({
                          country: country.countryCode,
                          phone: `+${country.dialCode.replace(/\D/g, '')}${value.replace(/\D/g, '')}`,
                        })
                      }}
                      inputClass={classNames(classes.phoneNumberInput, {
                        [classes.suggestedPhoneInput]: EventSuggestedNumbersHelper.isSuggestedNumber(
                          event,
                          phone.phone,
                        ),
                      })}
                    />
                    {index > 0 && (
                      <span onClick={() => removePhoneNumber(index)}>
                        <Icon name="close" className={classes.removeButton} />
                      </span>
                    )}
                  </Box>
                )}
              />
            </FormControl>
          ))}
          {amountOfReceiverNumbers < MAX_RECEIVER_NUMBERS && (
            <Typography variant="body" className={classes.textButton} onClick={handleAddPhoneNumber}>
              + <FormattedText id="configuration.editReminderModal.textButton.addPhone" />
            </Typography>
          )}
          <Accordion
            Title={({ isActive }) => (
              <Typography variant="body" className={classes.accordionTitle}>
                {isActive ? (
                  <FormattedText id="configuration.editReminderModal.accordion.active" />
                ) : (
                  <FormattedText id="configuration.editReminderModal.accordion.notActive" />
                )}
                <Icon
                  name="arrowLeft"
                  className={classNames(classes.accordionIcon, {
                    [classes.accordionIconActive]: isActive,
                  })}
                />
              </Typography>
            )}
            body={
              <>
                <TextField
                  disabled={isPastEvent}
                  wrapperClassName={classes.textFieldWrapper}
                  label={formatMessage({
                    id: 'configuration.step1.label.from',
                  })}
                  {...register('sender_name')}
                  error={errors.sender_name?.message}
                />
                <TextField
                  disabled={isPastEvent}
                  wrapperClassName={classes.textFieldWrapper}
                  label="Meeting Location"
                  Element="textarea"
                  {...register('location')}
                  error={errors.location?.message}
                />
                <MessageInput
                  wrapperClassName={classes.textFieldWrapper}
                  disabled={isPastEvent}
                  label={formatMessage({
                    id: 'configuration.editReminderModal.label.message',
                  })}
                  rows={3}
                  {...register('text')}
                  error={errors.text?.message}
                  showDynamicDataHelper={!isPastEvent}
                  confirmationsEnabled={profileData.appointment_confirmation}
                  messagePreview={eventMessage}
                />
                <FormControl
                  label={formatMessage({
                    id: 'configuration.step1.timing.title',
                  })}
                  error={errors?.time_before?.message}
                  className={classNames(classes.textFieldWrapper, {
                    [classes.disabled]: isPastEvent,
                  })}
                >
                  <Controller
                    control={control}
                    defaultValue={[]}
                    name="time_before"
                    render={({ field: { onChange, value, ref } }) => (
                      <SelectField
                        isDisabled={isPastEvent}
                        ref={ref}
                        value={timingOptions?.filter((c) => value.includes(c.value))}
                        onChange={(val) => onChange(val.map((c) => c.value))}
                        options={timingOptions}
                        isMulti
                      />
                    )}
                  />
                </FormControl>
              </>
            }
          />
          <Button
            type="submit"
            className={classes.button}
            widthType="content"
            disabled={isPastEvent}
            isLoading={isSubmitting}
          >
            <FormattedText id="configuration.editReminderModal.button" />
          </Button>
          {(Object.keys(errors).length > 0 || event.errors.length > 0) && (
            <Typography variant="body" className={classes.error} style={{ textAlign: 'center' }}>
              {[extractErrors(errors), ...event.errors].join(', ')}
            </Typography>
          )}
          <Typography variant="body" className={classes.clickableText} onClick={() => setIsPreview(true)}>
            <Icon name="eye" className={classes.eyeIcon} />
            <FormattedText id="configuration.editReminderModal.clickableButton.previewMessage" />
          </Typography>
        </form>
      </Modal>
      {previewReminder && isPreview && (
        <PreviewReminderModal
          message={generateEventMessage(profileData, previewReminder)}
          onClose={handleClosePreview}
          onBack={handleClosePreview}
        />
      )}
      {sendImmediatelyState === 'open' && (
        <SendReminderImmediatelyModal
          reminderTiming={getMinExpiredReminderOffset(eventDate, watch('time_before').map(Number))}
          onClose={handleCloseReminderImmediatelyModal}
          onBack={handleCloseReminderImmediatelyModal}
          onSend={handleSendReminderImmediately}
        />
      )}
    </>
  )
}

function extractErrors(errors: FieldErrors) {
  if (!errors) return []
  const messages = Object.values(errors).flatMap((error) => {
    if (Array.isArray(error)) {
      return error.flatMap((err) => extractErrors(err))
    } else if (error && typeof error === 'object') {
      return error.message ? [error.message] : []
    }
    return []
  })
  return Array.from(new Set(messages))
}

function getMinExpiredReminderOffset(eventDate: Date, timingValues: number[]): number {
  // This is for showing the send immediately modal
  // Suppose the event starts in 35 minutes, and the user has set reminders for 1d, 1h and 30m
  // We want to return the smallest offset that is in the past, which is 1h
  // This will cause the modal to show: "1h reminder is in the past, set a reminder for 30m or less"
  const expiredOffsets = getExpiredReminderOffsets(eventDate, timingValues)
  return expiredOffsets.length ? Math.min(...expiredOffsets) : 0
}
