import classNames from 'classnames';
import { addDays, format } from 'date-fns';
import {
    Dispatch, FC, SetStateAction, useCallback, useContext, useEffect, useMemo,
    useRef, useState
} from 'react';
import {
    ErrorModal, FormattedText, Icon, Loader, Typography
} from 'src/modules/core/components';
import { EventCard } from 'src/modules/event/components';
import { groupEventsByDate } from 'src/modules/event/utils/helpers';
import classes from './event-list.module.scss';

import type { EventPreview, ExtendEventPreview } from 'src/modules/event/types/event.types'
import type { ErrorMessage } from 'src/modules/core/types/error.types'
export type EventListProps = {
  events: EventPreview[]
  isLoading?: boolean
  eventCardMode?: 'normal' | 'testReminder'
  onCardClick?: (eventPreview: ExtendEventPreview) => void
  changeSelectedDate?: Dispatch<SetStateAction<Date>>
}

const COLUMN_DIMENSIONS = {
  width: 230,
  gap: 12,
}

export function EventList({
  events,
  isLoading,
  eventCardMode = 'normal',
  onCardClick,
  changeSelectedDate,
}: EventListProps) {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>(null)
  const [loadingEventId] = useState<string>(null)

  const [daysCount, setDaysCount] = useState(5)
  const containerRef = useRef<HTMLDivElement>(null)

  const calculateColumns = useCallback(() => {
    const containerWidth = containerRef.current?.offsetWidth || 0
    const totalColumnWidth = COLUMN_DIMENSIONS.width + COLUMN_DIMENSIONS.gap
    const maxColumns = Math.floor(containerWidth / totalColumnWidth)
    setDaysCount(maxColumns)
  }, [setDaysCount])

  useEffect(() => {
    calculateColumns()
    window.addEventListener('resize', calculateColumns)
    return () => {
      window.removeEventListener('resize', calculateColumns)
    }
  }, [calculateColumns])

  const filteredEvents = useMemo(() => {
    return groupEventsByDate(events, addDays(selectedDate, 0), addDays(selectedDate, daysCount))
  }, [events, selectedDate, daysCount])

  const onDateChange = useCallback(
    (number: number) => () => {
      setSelectedDate((prevState) => addDays(prevState, number))
      changeSelectedDate && changeSelectedDate((prevState) => addDays(prevState, number))
    },
    [changeSelectedDate],
  )

  const handleCardClick = useCallback(
    (eventPreview: ExtendEventPreview) => () => {
      onCardClick && onCardClick(eventPreview)
    },
    [onCardClick],
  )

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <div className={classes.leftButtons}>
          <div className={classes.arrowWrapper} onClick={onDateChange(-daysCount)}>
            <Icon name="arrowLeftRounded" className={classes.arrow} />
          </div>
        </div>
        <Typography variant="h2" className={classes.month}>
          {format(selectedDate, 'MMMM uuuu')}
        </Typography>
        <div className={classes.arrowWrapper} onClick={onDateChange(daysCount)}>
          <Icon name="arrowLeftRounded" className={classNames(classes.arrow, classes.arrowRight)} />
        </div>
      </div>
      <div className={classes.remindersContainer} ref={containerRef}>
        <div className={classes.remindersList}>
          <Loader className={classes.loader} isLoading={isLoading} />
          {!isLoading &&
            filteredEvents.map((dayEvents) => (
              <div key={dayEvents.date} className={classes.remindersListItem}>
                <Typography className={classes.dayTitle} variant="body">
                  {dayEvents.date}
                </Typography>
                <div>
                  {dayEvents.events.map((event) => (
                    <div
                      key={`${event.id}-${event.event_date}`}
                      className={classes.reminder}
                      onClick={handleCardClick(event)}
                    >
                      <EventCard
                        showBadge={eventCardMode === 'normal'}
                        showSwitch={eventCardMode === 'normal'}
                        showStatus={eventCardMode === 'normal'}
                        showPhoneInputs={eventCardMode === 'normal'}
                        variant={'current'}
                        event={event}
                        isLoading={loadingEventId === event.id}
                      />
                    </div>
                  ))}
                  {dayEvents.events.length === 0 && (
                    <Typography variant="body" className={classes.textEmpty}>
                      <FormattedText id="reminder.text.empty" />
                    </Typography>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>

      {errorMessage && (
        <ErrorModal
          title={
            <Typography variant="body" className={classes.errorTitle}>
              {errorMessage.title}
            </Typography>
          }
          description={
            <Typography variant="span" className={classes.errorDescription}>
              {errorMessage.description}
            </Typography>
          }
          onClose={() => setErrorMessage(null)}
        />
      )}
    </div>
  )
}
