import { useEffect, useState } from 'react'
import { FormattedText, Loader, SecondaryHeader } from 'src/modules/core/components'
import { ProfileInfo } from 'src/modules/user/components'
import { useProfile } from 'src/modules/user/hooks/use-profile'
import { Box, Container } from '@material-ui/core'
import { useTrack } from '../../hooks/use-track'
import { useFlag } from 'react-tggl-client'
import { ChooseModeForm } from './choose-mode-form'
import classes from './choose-mode-screen.module.scss'
import { ChooseModeFormAlternative } from './choose-mode-form-alternative'

export function ChooseModeScreen() {
  const profile = useProfile()
  const track = useTrack()
  const [hasTrackedEvent, setHasTrackedEvent] = useState(false)
  const { value: designType, loading: isFlagLoading } = useFlag('chooseModeAlternativeDesign')

  console.log('designType', designType)

  useEffect(() => {
    if (!profile.isLoading && profile.data) {
      if (!hasTrackedEvent) {
        track.mutate('onboarding_start')
        setHasTrackedEvent(true)
      }
    }
  }, [profile.isLoading, profile.data, hasTrackedEvent, track])

  if (profile.isLoading || isFlagLoading) {
    return <Loader isLoading={true} text={<FormattedText id="configuration.profileLoadingText" />} full />
  }

  return (
    <Container maxWidth="lg">
      <Box marginTop={6}>
        {designType === 'alternative' ? <ChooseModeFormAlternative /> : <ChooseModeForm />}
        <ProfileInfo rootClassName={classes.absoluteWrapper} />
      </Box>
    </Container>
  )
}
