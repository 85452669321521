import { FC, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'
// components
import { FormattedText, PageWrapper, Typography } from 'src/modules/core/components'
// constants
import { settingsTabs } from 'src/modules/user/constants/settings-tabs.constants'

import classes from './settings-info-wrapper.module.scss'

export const SettingsInfoWrapper: FC = () => {
  // hooks
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  // state
  const [tabIndex, setTabIndex] = useState<number>(0)
  const [prevTabIndex, setPrevTabIndex] = useState(0)

  useEffect(() => {
    const tab = +searchParams.get('tab')

    if (tab) {
      setTabIndex(tab - 1)
      setPrevTabIndex(tab - 2)
    }
  }, [searchParams])

  const handleTabChange = useCallback(
    (index: number) => () => {
      setTabIndex((prev) => {
        setPrevTabIndex(prev)

        return index
      })

      navigate(`${location.pathname}?tab=${index + 1}`)
    },
    [location.pathname, navigate],
  )

  return (
    <PageWrapper title={<FormattedText id="settings.header" />} className={classes.wrapper} authInfo>
      <div className={classes.root}>
        <div className={classes.tabs}>
          {settingsTabs.map(({ title }, i) => (
            <Typography
              key={`${title}${i}`}
              variant="body"
              className={classNames(classes.tab, {
                [classes.selected]: tabIndex === i,
              })}
              onClick={handleTabChange(i)}
            >
              {title}
            </Typography>
          ))}
        </div>
        {settingsTabs.map(({ component: Component }, index) => (
          <CSSTransition
            key={index}
            in={tabIndex === index}
            timeout={500}
            classNames={{
              enter: prevTabIndex < index ? classes.rightEnter : classes.leftEnter,
              enterActive: prevTabIndex < index ? classes.rightEnterActive : classes.leftEnterActive,
              exit: tabIndex < index ? classes.rightExit : classes.leftExit,
              exitActive: tabIndex < index ? classes.rightExitActive : classes.leftExitActive,
            }}
            unmountOnExit
          >
            <Component />
          </CSSTransition>
        ))}
      </div>
    </PageWrapper>
  )
}
