import classNames from 'classnames'
import { FC, memo, useMemo } from 'react'
import { Button, Features, FormattedText, Typography } from 'src/modules/core/components'
import { roundNumber } from 'src/modules/core/utils/helpers'
import { billingTypes, SHORT_BILLING_INTERVAL } from 'src/modules/payment/constants/payment.constants'
import { usePaddlePlanData } from '../../hooks/use-paddle-plan-data'
import { getLocalizedPaddlePlanPrice } from '../../utils/get-localized-paddle-plan-price'
import classes from './subscription-plan-card.module.scss'

import type { SubscriptionPlanCardProps } from './subscription-plan-card.interface'

const DAYS_IN_WEEK = 7
const MONTHS_IN_YEAR = 12

export const SubscriptionPlanCard: FC<SubscriptionPlanCardProps> = memo(
  ({ plan, currentPlanId, handleUpgradePlan, className, descriptionClassName, featuresClassName }) => {
    const paddlePlanData = usePaddlePlanData({ planId: plan.paddle_id })

    const price = useMemo(() => {
      if (paddlePlanData.data) {
        return getLocalizedPaddlePlanPrice(paddlePlanData.data)
      }
      const divisor = SHORT_BILLING_INTERVAL ? DAYS_IN_WEEK : MONTHS_IN_YEAR
      const amount = plan.billing_type === billingTypes.longInterval ? roundNumber(plan.price / divisor, 2) : plan.price
      return `$${amount}`
    }, [plan.billing_type, plan.price, paddlePlanData.data])

    // Free plan users don't have a subscription plan ID so we assume current plan is free plan
    const isCurrentPlan = currentPlanId === plan.paddle_id

    return (
      <div className={className}>
        <Typography variant="h2">{plan.name}</Typography>
        {plan?.description && (
          <Typography variant="span" className={descriptionClassName}>
            {plan.description}
          </Typography>
        )}
        {price !== null && (
          <div className={classes.price}>
            <Typography variant="h1">{price}</Typography>
            <Typography variant="span">
              / <FormattedText id="payment.month" />
            </Typography>
          </div>
        )}
        {handleUpgradePlan && price !== null && (
          <Button className={classes.button} disabled={isCurrentPlan} onClick={handleUpgradePlan(plan)}>
            {isCurrentPlan ? (
              <FormattedText id="payment.button.currentPlan" />
            ) : (
              <FormattedText id="payment.button.upgradePlan" />
            )}
          </Button>
        )}
        {plan?.benefits?.length && (
          <Features className={classNames(classes.features, featuresClassName)} list={plan.benefits} />
        )}
      </div>
    )
  },
)

SubscriptionPlanCard.displayName = 'SubscriptionPlanCard'
