import { FC } from 'react'
import { format } from 'date-fns'
import { Button, FormattedText, Icon, Typography } from 'src/modules/core/components'
import classes from './confirmation-form.module.scss'
import { formatInTimeZone } from 'date-fns-tz'

import type { ConfirmationFormProps } from './confirmation-form.interface'

export const ConfirmationForm: FC<ConfirmationFormProps> = ({ event, onConfirm, onCancel }) => {
  if (!event) {
    return null
  }

  function formatEventDate(formatString: string) {
    const eventDate = new Date(Number(event.event_date))
    const timezone = (event as any).timezone
    return timezone ? formatInTimeZone(eventDate, timezone, formatString) : format(eventDate, formatString)
  }

  return (
    <div>
      <Typography variant="h1" className={classes.title}>
        <FormattedText id="confirmation.title" />
      </Typography>
      <div className={classes.row}>
        <div className={classes.iconWrapper}>
          <Icon name="info" />
        </div>
        <Typography variant="body">{event.title || <FormattedText id="reminder.noTitle" />}</Typography>
      </div>
      <div className={classes.row}>
        <div className={classes.iconWrapper}>
          <Icon name="calendarAlt" />
        </div>
        <Typography variant="body">
          {formatEventDate('iiii, MMMM d @ ')}
          {event.all_day ? <FormattedText id="reminder.allDay" /> : formatEventDate('h:mmaaa')}
        </Typography>
      </div>
      <div className={classes.row}>
        <div className={classes.iconWrapper}>
          <Icon name="pin" />
        </div>
        <Typography variant="body">{event.location}</Typography>
      </div>
      <div className={classes.buttonsWrapper}>
        {event.confirmed === 'confirmed' ? (
          <div className={classes.confirmationMessageWrapper}>
            <div className={classes.confirmationSuccessMark}>
              <Icon name="checkmark" />
            </div>
            <Typography variant="body" className={classes.confirmationSuccessText}>
              <FormattedText id="confirmation.alreadyConfirmed" />
            </Typography>
          </div>
        ) : (
          <Button onClick={onConfirm}>
            <FormattedText id="confirmation.button.confirm" />
          </Button>
        )}
        <Button variant="danger" className={classes.cancelButton} onClick={onCancel}>
          <FormattedText id="confirmation.button.cancel" />
        </Button>
      </div>
    </div>
  )
}
