import classNames from 'classnames';
import { format } from 'date-fns';
import Tooltip from 'rc-tooltip';
import { ChangeEvent, MouseEvent, useCallback, useMemo, useState } from 'react';
import { NestedValue } from 'react-hook-form';
import { UserDto } from 'src/helpers/user.dto';
import { useCalendars } from 'src/modules/calendar/hooks/use-calendars';
import {
    confirmationVariants
} from 'src/modules/confirmation/constants/confirmation-variants';
import {
    FormattedText, Icon, ToggleSwitch, Typography
} from 'src/modules/core/components';
import focusAwareComponent from 'src/modules/core/utils/focus-aware-component';
import { useProfile } from 'src/modules/user/hooks/use-profile';
import { Box } from '@material-ui/core';
import { EventPreview, ExtendEventPreview } from '../../types/event.types';
import { getExpiredReminderOffsets } from '../../utils/helpers';
import { EventCardPhoneFields } from './event-card-phone-fields';
import { EventCardStatus } from './event-card-status';
import classes from './event-card.module.scss';

export type EventCardVariant = 'finished' | 'current'
type OnChangeHandler = (e: ChangeEvent<HTMLInputElement>) => void

export type EventCardProps = {
  onSwitchChange?: OnChangeHandler
  variant: EventCardVariant
  event: ExtendEventPreview
  eventRepresentsReminder?: boolean
  showBadge?: boolean
  showSwitch?: boolean
  showStatus?: boolean
  showPhoneInputs?: boolean
  isLoading?: boolean
  hasFocus?: boolean
  onCardClick?: (event: ExtendEventPreview, phoneNumbers: ReceiverNumber[]) => void
}

export type ReceiverNumber = {
  id: string
  phone: string
  country: string
}

export type EditEventForm = {
  dummy?: string
  receiverNumbers: NestedValue<ReceiverNumber[]>
}

const offsetBottom = -20

export const EventCard = focusAwareComponent<EventCardProps>(
  ({
    variant,
    event,
    eventRepresentsReminder = false,
    showBadge = true,
    showSwitch = true,
    showStatus = true,
    showPhoneInputs = true,
    onSwitchChange,
    isLoading,
    hasFocus,
    onCardClick,
  }) => {
    const profile = useProfile()
    const calendars = useCalendars()
    const [currentPhoneNumbers, setCurrentPhoneNumbers] = useState(null)

    const eventDate = useMemo(() => new Date(Number(event.event_date)), [event.event_date])
    const originalEventDate = useMemo(
      () => new Date(Number(event.original_event_date ?? event.event_date)),
      [event.original_event_date, event.event_date],
    )

    const isPastEvent = useMemo(() => new Date() > eventDate, [eventDate])
    const isTooLateForReminders = useMemo(() => isTooLateToSendReminders(profile.data, event), [profile.data, event])
    const hasPhoneNumber = useMemo(() => event.receiver_numbers.length > 0, [event.receiver_numbers])
    const hideSwitch = useMemo(
      () => !showSwitch || isPastEvent || !hasPhoneNumber || isTooLateForReminders,
      [showSwitch, isPastEvent, hasPhoneNumber, isTooLateForReminders],
    )

    const acceptanceVariant = useMemo(() => {
      if (!showBadge || !event.active || event.confirmed === null || (event.confirmed === 'pending' && isPastEvent)) {
        return null
      }

      if (event.confirmed === 'pending') {
        return confirmationVariants.waiting
      }

      if (event.confirmed === 'confirmed') {
        return confirmationVariants.accept
      }

      if (event.confirmed === 'cancelled') {
        return confirmationVariants.reject
      }

      return null
    }, [showBadge, event.active, event.confirmed, isPastEvent])

    const parentCalendar = useMemo(
      () => calendars.data?.find((calendar) => calendar.id === event.calendar),
      [calendars.data, event.calendar],
    )

    const handleCardClick = useCallback(() => {
      if (onCardClick) {
        onCardClick(event, currentPhoneNumbers)
      }
    }, [onCardClick, event, currentPhoneNumbers])

    return (
      <div
        className={classNames(classes.root, {
          [classes.clickable]: variant === 'current',
        })}
        onClick={handleCardClick}
      >
        {acceptanceVariant && (
          <Tooltip
            placement="top"
            mouseEnterDelay={0.1}
            motion={{ motionName: 'example' }}
            align={{ offset: [0, offsetBottom] }}
            overlayClassName={classes.tooltipOverlay}
            overlay={
              <Typography variant="body" className={classes.bodyText}>
                {acceptanceVariant === confirmationVariants.accept && (
                  <FormattedText id="confirmation.tooltip.accept" />
                )}
                {acceptanceVariant === confirmationVariants.waiting && (
                  <FormattedText id="confirmation.tooltip.pending" />
                )}
                {acceptanceVariant === confirmationVariants.reject && (
                  <FormattedText id="confirmation.tooltip.cancel" />
                )}
              </Typography>
            }
          >
            <div
              className={classNames(classes.statusCircle, {
                [classes.statusCircleAccept]: acceptanceVariant === confirmationVariants.accept,
                [classes.statusCircleWaiting]: acceptanceVariant === confirmationVariants.waiting,
                [classes.statusCircleRejected]: acceptanceVariant === confirmationVariants.reject,
              })}
            >
              {acceptanceVariant === confirmationVariants.accept && <Icon name="markAccepted" />}
              {acceptanceVariant === confirmationVariants.waiting && <Icon name="markWaiting" />}
              {acceptanceVariant === confirmationVariants.reject && <Icon name="markRejected" />}
            </div>
          </Tooltip>
        )}
        <div className={classes.statusAndSwitchContainer}>
          {showStatus && (
            <div>
              <EventCardStatus event={event} eventDateIsReminderDate={eventRepresentsReminder} />
            </div>
          )}
          {!hideSwitch && (
            <div className={classes.flexWrapper}>
              {isLoading && (
                <div className={classes.spinnerFlexWrapper}>
                  <div className={classes.spinnerWrapper}>
                    <div className={classes.spinner}>
                      <div />
                    </div>
                  </div>
                </div>
              )}
              <div className={classes.toggleWrapper} onClick={(e: MouseEvent) => e.stopPropagation()}>
                {variant === 'current' ? (
                  <ToggleSwitch
                    checked={event.active}
                    onChange={onSwitchChange}
                    readOnly={!onSwitchChange || (isPastEvent && !event.active)}
                    disabled={isLoading}
                    variant="primary"
                  />
                ) : (
                  <Icon name="successCheckmark" />
                )}
              </div>
            </div>
          )}
        </div>
        <div>
          <div className={classes.titleWrapper}>
            <div className={classes.circle} style={parentCalendar ? { backgroundColor: parentCalendar.color } : {}} />
            <p className={classes.title} title={event.title}>
              {event.title || <FormattedText id="reminder.noTitle" />}
            </p>
            <input type="hidden" value={event.id} />
          </div>
        </div>
        <div className={classes.flexWrapper}>
          <Typography variant="body" className={classes.regular}>
            {eventRepresentsReminder ? 'Reminder sent at ' : ''}
            {event.all_day ? <FormattedText id="reminder.allDay" /> : format(originalEventDate, 'h:mm a')}
          </Typography>
        </div>

        {showPhoneInputs && profile.data.usage_mode === 'customer' && !event.active && !isPastEvent && (
          <EventCardPhoneFields
            profile={profile.data}
            event={event}
            hasFocus={hasFocus}
            onPhoneNumbersChange={setCurrentPhoneNumbers}
          />
        )}

        {hasFocus && event.errors.length > 0 && event.active && (
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gridGap={3} marginTop={1}>
            <Icon name="eventCardError" />
            <span className={classes.error}>{event.errors.join(', ')}</span>
          </Box>
        )}
      </div>
    )
  },
)

function isTooLateToSendReminders(profile: UserDto, event: EventPreview) {
  if (!profile || !event) return true
  const eventDate = new Date(Number(event.event_date))
  const timeBefore = profile.default_time_before
  const expiredOffsets = getExpiredReminderOffsets(eventDate, timeBefore)
  return expiredOffsets.length > 0
}
