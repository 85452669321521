export type EventData = {
  google_event_date?: string
  all_day?: boolean
  event_date?: string
}

export function getEventDate(event: EventData) {
  const { google_event_date, all_day, event_date } = event

  if (google_event_date) {
    if (all_day) {
      // For all-day events, google_event_date is a date-only ISO string
      const [year, month, day] = google_event_date.split('-').map(Number)
      return new Date(year, month - 1, day)
    }
    return new Date(google_event_date)
  }

  return new Date(Number(event_date))
}
