import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { FormattedTextProps } from './formatted-text.interface'

export const FormattedText = ({ html, ...props }: FormattedTextProps) => {
  if (html) {
    return (
      <FormattedMessage {...props}>
        {(txt) => <div dangerouslySetInnerHTML={{ __html: Array.isArray(txt) ? txt.join('') : String(txt) }} />}
      </FormattedMessage>
    )
  }
  return <FormattedMessage {...props} />
}
