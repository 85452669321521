import { FC } from 'react'
import { FormattedText, Modal, Typography } from 'src/modules/core/components'
import eventTimezoneSettingsImage from 'src/assets/images/event-timezone-settings.png'
import classes from './event-timezone-modal.module.scss'

import type { EventTimezoneModalProps } from './event-timezone-modal.interface'

export const EventTimezoneModal: FC<EventTimezoneModalProps> = ({ onClose }) => {
  return (
    <Modal
      onClose={onClose}
      title={
        <Typography variant="h3" className={classes.modalTitle}>
          <FormattedText id="settings.eventTimeZone.label" />
        </Typography>
      }
    >
      <div className={classes.modalContent}>
        <Typography variant="body" className={classes.modalText}>
          <FormattedText id="settings.eventTimeZone.description" html={true} />
        </Typography>

        <img className={classes.image} src={eventTimezoneSettingsImage} alt="Event Timezone Modal" />
      </div>
    </Modal>
  )
}
