import classNames from 'classnames';
import { addDays, format } from 'date-fns';
import Tooltip from 'rc-tooltip';
import {
    ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState
} from 'react';
import { useQueryClient } from 'react-query';
import {
    AutoReminderModal
} from 'src/modules/auto-reminders/auto-reminder-modal';
import {
    Button, ErrorModal, FormattedText, Icon, Loader, Typography
} from 'src/modules/core/components';
import { DateContext } from 'src/modules/core/context';
import { useSidebar } from 'src/modules/core/context/sidebar.context';
import { EventCard } from 'src/modules/event/components';
import { eventDetailsParams } from 'src/modules/event/constants/event-params';
import {
    maxMessageLengthWithConfirmation
} from 'src/modules/event/constants/event.constants';
import {
    GET_EVENT_BY_ID_CACHE_KEY
} from 'src/modules/event/hooks/use-event-by-id';
import {
    generateEventMessage, generateTimingWarningMessage,
    getExpiredReminderOffsets, validateEvent
} from 'src/modules/event/utils/helpers';
import {
    TypeformReviewModal
} from 'src/modules/review/typeform-review-modal/typeform-review-modal';
import {
    ReceiverNumber
} from 'src/modules/user/components/send-test-or-finish/send-test-reminder-form-modal/send-test-reminder-form-modal.interface';
import { useProfile } from 'src/modules/user/hooks/use-profile';
import { ApiService } from 'src/services/api.service';
import { groupEventsByDate } from '../../utils/group-events-by-date';
import classes from './event-list.module.scss';
import { TabSelect } from './tab-select';

import type { EventData, EventDetails, ExtendEventPreview } from 'src/modules/event/types/event.types'
import type { ErrorMessage } from 'src/modules/core/types/error.types'
import type { EventListProps } from './event-list.interface'
const TOOLTIP_OFFSET = {
  right: 0,
  bottom: 14,
}

const COLUMN_DIMENSIONS = {
  width: 230,
  gap: 12,
}

export function EventList({ events, onCardClick, onSwitchChange, changeSelectedDate, isLoading }: EventListProps) {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>(null)
  const [loadingEventId, setLoadingEventId] = useState<string>(null)

  const queryClient = useQueryClient()
  const profile = useProfile()

  const { daysCount, setDaysCount } = useContext(DateContext)
  const { isCollapsed } = useSidebar()
  const containerRef = useRef<HTMLDivElement>(null)

  const calculateColumns = useCallback(() => {
    const containerWidth = containerRef.current?.offsetWidth || 0
    const totalColumnWidth = COLUMN_DIMENSIONS.width + COLUMN_DIMENSIONS.gap
    const maxColumns = Math.floor(containerWidth / totalColumnWidth)
    setDaysCount(maxColumns)
  }, [setDaysCount])

  useEffect(() => {
    calculateColumns()
    window.addEventListener('resize', calculateColumns)
    return () => {
      window.removeEventListener('resize', calculateColumns)
    }
  }, [calculateColumns])

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      calculateColumns()
    }, 300)
    return () => clearTimeout(debounceTimer)
  }, [isCollapsed, calculateColumns])

  // Event grouping
  const eventsByDay = useMemo(() => {
    return groupEventsByDate(events, addDays(selectedDate, 0), addDays(selectedDate, daysCount))
  }, [events, selectedDate, daysCount])

  // Check if there are any real events
  const hasEvents = useMemo(() => {
    if (!eventsByDay.length) {
      return false
    }

    const allEvents = eventsByDay.flatMap((day) => day.events)
    if (allEvents.length === 0) return false
    if (allEvents.length === 1 && allEvents[0].title === 'SMS Reminder Setup') return false

    return true
  }, [eventsByDay])

  const onDateChange = useCallback(
    (number: number) => () => {
      setSelectedDate((prevState) => addDays(prevState, number))
      changeSelectedDate && changeSelectedDate((prevState) => addDays(prevState, number))
    },
    [changeSelectedDate],
  )

  const handleCardClick = useCallback(
    (eventPreview: ExtendEventPreview, phoneNumbers?: ReceiverNumber[]) => {
      onCardClick && onCardClick(eventPreview, phoneNumbers)
    },
    [onCardClick],
  )

  const handleReturnToToday = useCallback(() => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    setSelectedDate(today)
    changeSelectedDate?.(today)
  }, [changeSelectedDate])

  const handleSwitchChange = useCallback(
    async (eventPreview: ExtendEventPreview, isActivating: boolean) => {
      const id = eventPreview.id
      setLoadingEventId(id)

      const validateActivation = (event: EventData, eventDate: Date, profileData: any): boolean => {
        const isPastEvent = new Date() > eventDate
        const timingValues =
          event.default_time_before && !event.active
            ? profileData?.default_time_before
            : (event?.reminders?.data?.length > 0 && event.reminders.data.map((t) => t.time_before)) || []

        const withinTimingValues = getExpiredReminderOffsets(eventDate, timingValues)

        if (isPastEvent || withinTimingValues.length) {
          setErrorMessage({
            title: `Couldn't create SMS reminder`,
            description: generateTimingWarningMessage(withinTimingValues),
          })
          return false
        }

        const { isNumberError, isMessageError, isTimingError } = validateEvent(event, profileData)
        if (isNumberError || isMessageError || isTimingError) {
          if (onCardClick) {
            onCardClick(event, [])
            return false
          }
          setErrorMessage({
            title: `Couldn't create SMS reminder`,
            description: `There must be phone number, message and timing associated with the reminder.`,
          })
          return false
        }

        const messageLength = generateEventMessage({ ...profileData, appointment_confirmation: false }, event).length
        if (profileData.appointment_confirmation && messageLength > maxMessageLengthWithConfirmation) {
          setErrorMessage({
            title: 'SMS Message is Too Long',
            description: `Your reminder message is ${messageLength}. Messages must be less than 320 characters (239 characters if you're using Appointment Confirmations) including any dynamic text that's inserted`,
          })
          return false
        }

        return true
      }

      try {
        const eventDetails = await queryClient.fetchQuery<EventDetails>(
          [GET_EVENT_BY_ID_CACHE_KEY, id, eventDetailsParams],
          () => ApiService.Events.getEventById(id, eventDetailsParams),
          { staleTime: 300000 },
        )

        const event: EventData = { ...eventPreview, ...eventDetails }
        validateEvent(event, profile.data)
        const eventDate = new Date(parseInt(event.event_date, 10))

        if (isActivating) {
          if (!validateActivation(event, eventDate, profile.data)) {
            return
          }
        }

        onSwitchChange?.({ ...event, active: isActivating })
      } finally {
        setLoadingEventId(null)
      }
    },
    [queryClient, profile.data, onSwitchChange, onCardClick, setErrorMessage],
  )

  const renderEventList = () =>
    eventsByDay.map((dayEvents) => (
      <div key={dayEvents.date} className={classes.remindersListItem}>
        <Typography className={classNames(classes.dayTitle, { [classes.bold]: dayEvents.isToday })} variant="body">
          {dayEvents.date}
        </Typography>
        <div>
          {dayEvents.events.length > 0 ? (
            dayEvents.events.map((event) => (
              <div key={`${event.id}-${event.event_date}`} className={classes.reminder}>
                <EventCard
                  showBadge={profile.data?.appointment_confirmation}
                  showSwitch
                  variant={'current'}
                  event={event}
                  onSwitchChange={(e: ChangeEvent<HTMLInputElement>) => handleSwitchChange(event, e.target.checked)}
                  onCardClick={handleCardClick}
                  isLoading={loadingEventId === event.id}
                />
              </div>
            ))
          ) : (
            <Typography variant="body" className={classes.textEmpty}>
              <FormattedText id="reminder.text.empty" />
            </Typography>
          )}
        </div>
      </div>
    ))

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <div className={classes.dateHeading}>
          <div className={classes.leftButtons}>
            <div className={classes.arrowWrapper} onClick={onDateChange(-daysCount)}>
              <Icon name="arrowLeftRounded" className={classes.arrow} />
            </div>
            <Tooltip
              placement="bottom"
              mouseEnterDelay={0.1}
              motion={{ motionName: 'example' }}
              align={{ offset: [TOOLTIP_OFFSET.right, TOOLTIP_OFFSET.bottom] }}
              overlayClassName={classes.tooltipOverlay}
              overlay={
                <Typography variant="span" className={classes.bodyText}>
                  {format(new Date(), 'EEEE, LLLL d')}
                </Typography>
              }
            >
              <Button className={classes.button} onClick={handleReturnToToday}>
                <FormattedText id="dashboard.button.today" />
              </Button>
            </Tooltip>
          </div>
          <div className={classes.arrowWrapper} onClick={onDateChange(daysCount)}>
            <Icon name="arrowLeftRounded" className={classNames(classes.arrow, classes.arrowRight)} />
          </div>
          <Typography variant="h2" className={classes.month}>
            {format(selectedDate, 'MMMM uuuu')}
          </Typography>
        </div>
        <TabSelect />
      </div>
      {/* Event list */}
      <div className={classes.remindersContainer} ref={containerRef}>
        <div className={classes.remindersList}>
          <Loader className={classes.loader} isLoading={isLoading} />
          {!isLoading && renderEventList()}
        </div>
        {!isLoading && !hasEvents && (
          <div className={classes.noRemindersAlert}>
            <Typography variant="body" className={classes.noEventsText}>
              Add an appointment to your Google Calendar, then come back here to set a reminder on it.
            </Typography>
          </div>
        )}
      </div>

      {/* Modals */}
      {errorMessage && (
        <ErrorModal
          title={
            <Typography variant="body" className={classes.errorTitle}>
              {errorMessage.title}
            </Typography>
          }
          description={
            <Typography variant="span" className={classes.errorDescription}>
              {errorMessage.description}
            </Typography>
          }
          onClose={() => setErrorMessage(null)}
        />
      )}
      <TypeformReviewModal />
      <AutoReminderModal />
    </div>
  )
}
