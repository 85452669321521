import { FC, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { FormattedText, Icon, SecondaryHeader } from 'src/modules/core/components'
import { ConfigurationStepContext } from 'src/modules/core/context'
import { useOptimisticUpdateProfile } from 'src/modules/user/hooks/use-optimistic-update-profile'
import * as amplitude from '@amplitude/analytics-browser'
import { Box, Button, Container, Typography } from '@material-ui/core'
import { UsageMode } from '../../types/user-api.types'
import classes from './choose-mode-form-alternative.module.scss'
import businessModeBg from 'src/assets/images/business-mode-bg.png'
import businessModeButtonArrow from 'src/assets/images/business-mode-button-arrow.png'

interface ProfileFormData {
  usageMode: UsageMode
}

export const ChooseModeFormAlternative: FC = () => {
  const { handleSubmit, register, setValue } = useForm<ProfileFormData>()
  const { handleNext } = useContext(ConfigurationStepContext)
  const optimisticUpdateProfile = useOptimisticUpdateProfile()

  const onSubmit = ({ usageMode }: ProfileFormData) => {
    amplitude.track(usageMode === 'personal' ? 'Onboarding_selectPersonal' : 'Onboarding_selectCustomer')
    optimisticUpdateProfile.mutate({ usage_mode: usageMode }, { onSuccess: () => handleNext() })
  }

  return (
    <Container maxWidth="lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register('usageMode')} />
        <div className={classes.rootcontainer}>
          <div className={classes.header}>
            <Typography className={classes.title} variant="h1">
              <FormattedText id="chooseMode.title" />
            </Typography>
            <Typography className={classes.description} variant="body1">
              <FormattedText id="chooseMode.description" />
            </Typography>
          </div>
          <div className={classes.cardsContainer}>
            {/* Business Card */}
            <Box display="flex" flexDirection="row" className={classes.businessCard}>
              <div className={classes.left}>
                <Box display="flex" flexDirection="column" gridGap={2}>
                  <Box display="flex" alignItems="center" gridGap={1}>
                    <Typography variant="caption" className={classes.popularBadge}>
                      <FormattedText id="Most Popular" />
                    </Typography>
                  </Box>
                  <Typography variant="h5" component="h2" className={classes.title}>
                    <FormattedText id="For Business" />
                  </Typography>
                  <Typography variant="body1" className={classes.description}>
                    <FormattedText id="Send SMS appointment reminders to your clients or customers." />
                  </Typography>
                </Box>
                <div className={classes.phonePreview}>
                  <img src={businessModeBg} />
                </div>
              </div>
              <div className={classes.right}>
                <div className={classes.textContainer}>
                  <Typography variant="h5" component="h2" className={classes.title}>
                    <FormattedText id="Send Reminders to Customers" />
                  </Typography>
                  <Box className={classes.features}>
                    <Box display="flex" alignItems="flex-start" gridGap={10}>
                      <Icon name="successCheckmark" className={classes.checkIcon} />
                      <Box>
                        <Typography variant="subtitle1" className={classes.featureTitle}>
                          <FormattedText id="Reduce No-Shows" />
                        </Typography>
                        <Typography variant="body2" color="textSecondary" className={classes.featureDescription}>
                          <FormattedText id="Automated reminders help ensure customers don't forget appointments." />
                        </Typography>
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="flex-start" gridGap={10}>
                      <Icon name="successCheckmark" className={classes.checkIcon} />
                      <Box>
                        <Typography variant="subtitle1" className={classes.featureTitle}>
                          <FormattedText id="Confirm or Cancel" />
                        </Typography>
                        <Typography variant="body2" color="textSecondary" className={classes.featureDescription}>
                          <FormattedText id="Customers can confirm or cancel their appointment from the reminder message." />
                        </Typography>
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="flex-start" gridGap={10}>
                      <Icon name="successCheckmark" className={classes.checkIcon} />
                      <Box>
                        <Typography variant="subtitle1" className={classes.featureTitle}>
                          <FormattedText id="Set Reminders from Your Phone" />
                        </Typography>
                        <Typography variant="body2" color="textSecondary" className={classes.featureDescription}>
                          <FormattedText id="Use any calendar app on your mobile phone to set reminders." />
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </div>
                <div className={classes.buttonContainer}>
                  <img src={businessModeButtonArrow} />
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    onClick={() => setValue('usageMode', 'customer')}
                    type="submit"
                    disabled={optimisticUpdateProfile.isLoading}
                    className={classes.button}
                  >
                    <Icon name="arrowRightMedium" />
                    <FormattedText id="Send reminders to customers" />
                  </Button>
                </div>
              </div>
            </Box>

            {/* Personal Card */}
            <Box display="flex" flexDirection="row" gridGap={3} className={classes.personalCard}>
              <div className={classes.left}>
                <Box display="flex" flexDirection="column" gridGap={2}>
                  <Typography variant="h5" component="h2" className={classes.title}>
                    <FormattedText id="For Yourself" />
                  </Typography>
                  <Typography variant="body1" className={classes.description}>
                    <FormattedText id="Send SMS reminders to yourself." />
                  </Typography>
                </Box>
              </div>
              <div className={classes.right}>
                <Box display="flex" flexDirection="column" gridGap={2}>
                  <Typography variant="body1" className={classes.description}>
                    <FormattedText id="Never miss an important meeting. Send yourself an SMS automatically for all the appointments in your calendar or set them individually." />
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    onClick={() => setValue('usageMode', 'personal')}
                    type="submit"
                    disabled={optimisticUpdateProfile.isLoading}
                    className={classes.button}
                  >
                    <Icon name="arrowRightMedium" />
                    <FormattedText id="Send reminders to myself" />
                  </Button>
                </Box>
              </div>
            </Box>
          </div>
        </div>
      </form>
    </Container>
  )
}
